@import '~govuk-frontend/dist/govuk/base';

.table {
  td {
    vertical-align: middle;
  }
}

.title {
  max-width: 500px;
  overflow-wrap: break-word;
}

.fileSize {
  text-align: right;
  white-space: nowrap;
  width: 5rem;
}

.fileStatus {
  max-width: 126px;
}

.actions {
  --verticalSpacing: #{govuk-spacing(1)};
  margin-bottom: 0;

  @include govuk-media-query($until: desktop) {
    align-items: start;
    flex-direction: column;
    white-space: nowrap;
  }
}
